import React, { useState } from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { ReactSVG } from 'react-svg'
// components
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import logo from '../../images/leckerbissen-logo.svg'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }
  const data = useStaticQuery(graphql`
  query MenuItems{
    allProductosJson {
      edges {
        node {
          name
          path
        }
      }
    }
  }
`)
  return (<StyledContainer>
    <AppBar
      elevation={0}
      className='header'
    >
      <Toolbar>
        <Link className='brand' to={`/`}>
          <ReactSVG src={logo} className="svg-logo" />
        </Link>
        <Hidden xsDown>
          <Typography className='nav-bar' color='white' style={{ marginLeft: 'auto' }}>
            {data.allProductosJson.edges.map((menuItem) =>
              <Link activeClassName="active" key={menuItem.node.name} className='nav-link' to={`/${menuItem.node.path}`}>{menuItem.node.name}</Link>
            )}
            <Link activeClassName="active" className='nav-link' to={`/contacto`}>Contacto</Link>
          </Typography>
        </Hidden>
        <Hidden smUp>
          <IconButton
            style={{ marginLeft: 'auto' }}
            color="inherit"
            aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {data.allProductosJson.edges.map((menuItem) =>
              <Link activeClassName="active" key={menuItem.node.name} to={`/${menuItem.node.path}`}>
                <MenuItem onClick={handleClose}>{menuItem.node.name}</MenuItem>
              </Link>
            )}
            <Link activeClassName="active" to={`/contacto`}>
              <MenuItem onClick={handleClose}>Contacto</MenuItem>
            </Link>
          </Menu>
        </Hidden>
      </Toolbar>
    </AppBar>
  </StyledContainer>)
}

const StyledContainer = styled.div`
  .MuiIconButton-label{
    svg{fill: saddlebrown} 
  }
  .header{
    background: #fff;
  }
  .nav-bar{
    .nav-link{
      color: saddlebrown;
      position: relative;
      &:after{
        display:block;
        position: absolute;
        left:0;
        right:0;
        bottom:-5px;
        margin: 0 auto;
        width:0px;
        background:saddlebrown;
        height:2px;
        transition: all 2s ease;
        content:"";
      }
      &.active{
        &:after{
          width:100%;
        transition: all 2s ease;
        }
      }
    }
    .nav-link + .nav-link{
      margin-left: 20px;
    }
  }
  .svg-logo{
    width: 200px;
    svg{
      width: 100%;
    }
    path{
    fill: saddlebrown;
    }
  }
`
export default Header

import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { ReactSVG } from 'react-svg'
import logo from '../../images/leckerbissen-logo.svg'
import { Grid, Container, Box, Typography, Button } from '@material-ui/core'
import { FaInstagram } from 'react-icons/fa'

const PreFooter = () => {
  const data = useStaticQuery(graphql`
  query papu{
    allProductosJson {
      edges {
        node {
          name
          path
        }
      }
    }
  }
`)
  return <PFooter>
    <Box component="section" py={5}>
      <Container>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={2}>
            <ReactSVG src={logo} className="svg-logo" />
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Typography gutterBottom variant="h6" component="h3">
              Productos
            </Typography>

            {data.allProductosJson.edges.map((menuItem) =>
              <div key={menuItem.node.name}>
                <Button component={Link} to={`/${menuItem.node.path}`} color="inherit">{menuItem.node.name}</Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={5} style={{ textAlign: 'right' }}>
            <Typography gutterBottom variant="h6" component="h3">
              Contacto
            </Typography>
            <a href="mailto:info@leckerbissen.com.ar" rel="noreferrer" target="_blank">
              <Typography gutterBottom variant="p" component="p">
                info@leckerbissen.com.ar
            </Typography>
            </a>
            <a href="https://www.instagram.com/barritaslecker/" rel="noreferrer" target="_blank">
              <FaInstagram size='1.5rem' />
            </a>
            {/* <Typography gutterBottom variant="p" component="p">
              +54 11 5582 9412
            </Typography>
            <Typography gutterBottom variant="p" component="p">
              Av. directorio 0000 - CABA - Argentina
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  </PFooter>
}
const PFooter = styled.div`
  .MuiButton-root{
    padding: 0;
    text-transform: capitalize;

    &:hover{
      background: none;
    }
  }
  .svg-logo{
    width: 150px;
    svg{
      width: 100%;
    }
    path{
    fill: saddlebrown;
    }
  }
`

export default PreFooter
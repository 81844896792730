/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Header from './header'
import PreFooter from './preFooter'
import Footer from './footer'
import { FaWhatsapp } from 'react-icons/fa'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Container style={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            <main>{children}</main>
            <PreFooter />
            <Footer />
          </div>
          <WhatsappIcon href="https://wa.me/541123284032" target='_blank' ><FaWhatsapp /></WhatsappIcon>
        </Container>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Container = styled.div`
  .MuiTypography-h1,
  .MuiTypography-h2,
  .MuiTypography-h3,
  .MuiTypography-h4,
  .MuiTypography-h5,
  .MuiTypography-h6{
    font-family: 'Bilbo Swash Caps', cursive;
  }
`

const WhatsappIcon = styled.a`
  position: fixed;
  right:20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  background-color: #1ebea5;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  box-shadow: 0 3px 8px rgba(0,0,0,.5);
  z-index: 5;

  &:hover{
    opacity: .8;
  }
`
